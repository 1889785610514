import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["status", "weightsCsvField"];

  connect() {
    if (!window.CSVBoxImporter) {
      this.loadCSVBoxScript().then(() => {
        this.initializeCSVBoxImporter();
      }).catch(error => {
        console.error("Failed to load CSVBox script:", error);
      });
    } else {
      this.initializeCSVBoxImporter();
    }
  }

  disconnect() {
    // Cleanup
    if (this.csvBoxImporter) {
      // If CSVBox provides a cleanup method, call it here
      this.csvBoxImporter = null;
    }
  }

  loadCSVBoxScript() {
    return new Promise((resolve, reject) => {
      if (document.querySelector('script[src*="csvbox.io"]')) {
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.src = "https://js.csvbox.io/script.js";
      script.onload = () => resolve();
      script.onerror = () => reject(new Error("Failed to load script"));
      document.head.appendChild(script);
    });
  }

  initializeCSVBoxImporter() {
    if (this.csvBoxImporter) return; // Prevent double initialization

    const callback = (result, data) => {
      const statusElement = this.statusTarget;
      if (result) {
        statusElement.innerText = `Weights uploaded successfully. ${data.row_success} Animal Weights uploaded.`;
        statusElement.style.color = "green";
        this.weightsCsvFieldTarget.value = data.url;
      }
    };

    this.csvBoxImporter = new CSVBoxImporter(this.data.get("key"), {}, callback);
    this.csvBoxImporter.setUser({
      user_id: this.data.get("userId"),
      assessment_id: this.data.get("assessmentId")
    });
  }

  openModal(event) {
    event.preventDefault();
    if (this.csvBoxImporter) {
      this.csvBoxImporter.openModal();
    }
  }
}