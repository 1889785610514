import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="save-all"
export default class extends Controller {
  static targets = ["submitButton", "saveAllField"];

  connect() {
    // Prevent double initialization
    if (this.element.dataset.saveAllInitialized) return
    
    this.formChanged = false;
    this.userConfirmedLeave = false;

    // Check for the presence of the form and initialize listeners if it exists
    this.initializeFormListeners();
    
    // Mark as initialized
    this.element.dataset.saveAllInitialized = 'true'
  }

  disconnect() {
    // Clean up the initialization flag
    delete this.element.dataset.saveAllInitialized
    
    // Remove event listeners
    document.removeEventListener('turbo:before-visit', this.handleBeforeVisit)
    document.removeEventListener('turbo:submit-end', this.resetSaveAllField)
    document.removeEventListener('turbo:submit-end', this.resetSaveProgressButtons)
  }

  initializeFormListeners() {
    const form = this.element.querySelector('#wizard_form');

    if (form) {
      const formElements = form.querySelectorAll('input, textarea');
      formElements.forEach(element => {
        element.addEventListener('input', () => {
          this.formChanged = true;
          this.enableSaveAllButtons();
        });
      });

      form.addEventListener('submit', () => {
        this.formChanged = false;
        this.userConfirmedLeave = true;
      });

      // Listen to changes on select2 elements
      const select2Elements = form.querySelectorAll('.select2');
      select2Elements.forEach(element => {
        $(element).on('change', () => {
          this.formChanged = true;
          this.enableSaveAllButtons();
        });
      });

      // Listen to changes on select elements
      const selectElements = form.querySelectorAll('select');
      selectElements.forEach(element => {
        $(element).on('change', () => {
          this.formChanged = true;
          this.enableSaveAllButtons();
        });
      });


      // handling toggle buttons
      const updateButtons = form.querySelectorAll('.sinkable');
      updateButtons.forEach(button => {
        button.addEventListener('click', () => {
          this.formChanged = true;
          this.enableSaveAllButtons();
        });
      });

      this.updateSaveAllButtons();
      document.addEventListener('turbo:before-visit', this.handleBeforeVisit.bind(this));
      document.addEventListener('turbo:submit-end', this.resetSaveAllField.bind(this));
      document.addEventListener('turbo:submit-end', this.resetSaveProgressButtons.bind(this));
    } else {
      console.warn('Form not found');
    }
  }

  updateSaveAllButtons() {
    const saveAllButtons = this.element.querySelectorAll('[data-save-all-target="saveAllButton"]');
    saveAllButtons.forEach(button => {
      button.addEventListener('click', (event) => {
        event.preventDefault();
        
        // Handle clicks on the icon inside the button
        const clickedElement = event.target;
        if (clickedElement.tagName === 'I' || clickedElement.tagName === 'i') {
          event.stopPropagation(); // Prevent double triggering
        }
        
        this.submitForm(event);
        this.userConfirmedLeave = true;
      });
    });
  }

  enableSaveAllButtons() {
    this.updateSaveAllField();
    const saveAllButtons = this.element.querySelectorAll('[data-save-all-target="saveAllButton"]');
    saveAllButtons.forEach(button => {
      button.classList.remove('disabled');
    });
  }

  updateSaveAllField() {
    const saveAllField = this.element.querySelector('#save_all_field');
    if (saveAllField) {
      saveAllField.value = 'true';
    }
  }

  submitForm(event) {
    const form = this.element.querySelector('#wizard_form');
    if (form) {
      const originalAction = form.action;
      
      // Check if this is a save progress action by checking both the button and its icon
      const isSaveProgress = event?.target?.matches('[data-save-all-target="saveAllButton"], [data-save-all-target="saveAllButton"] *');
      
      if (isSaveProgress) {
        // Update form action to save route
        form.action = form.action.replace(/\/update_step\d+/, '/save_progress');
      }
      
      const submitButton = form.querySelector('[data-save-all-target="submitButton"]');
      if (submitButton) {
        submitButton.click();
        
        if (isSaveProgress) {
          form.action = originalAction;
        }
      } else {
        console.warn('Submit button not found');
      }
    } else {
      console.warn('Form not found for submitting');
    }
  }

  handleBeforeVisit(event) {
    var saveAll = document.getElementById('save_all_field').value
    if (!this.userConfirmedLeave && this.formChanged && saveAll === 'true') {
      if (!confirm('You have unsaved changes. Are you sure you want to leave?')) {
        event.preventDefault();
      } else {
        this.userConfirmedLeave = true;
      }
    }
  }

  resetSaveAllField() {
    const saveAllField = this.element.querySelector('#save_all_field');
    if (saveAllField) {
      saveAllField.value = 'false';
    }
  }

  resetSaveProgressButtons() {
    const saveProgressButtons = this.element.querySelectorAll('[data-save-all-target="saveAllButton"]');
    saveProgressButtons.forEach(button => {
      button.classList.add('disabled');
    });
  }
}
